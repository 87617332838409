@import '../variables';

.entry {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 50px 0;

  @media only screen and (max-width: 767px) {
    margin-bottom: 25px;
  }
}

.role {
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.role, .range {
  font-weight: 500;
}

.range {
  text-align: right;
}

.place {
  font-style: italic;
  width: 100%;
  font-size: .9em;
  color: $secondaryFontColor;
  margin: 2px 0 0 0;
}

.achievements {
  width: 100%;
}
