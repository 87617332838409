@import '../variables';

.social {
  margin: 50px auto 0 auto;
  text-align: center;
}

.icon {
  font-size: 3em;
  color: $secondaryFontColor;
  margin: 0 5%;

  &:hover {
    color: $fontColor;

  }
}
