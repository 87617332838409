@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../variables';

.App {
  background-color: $resumeBackground;
  width: 85%;
  max-width: 1400px;
  margin: 5vh auto 5vh
  auto;
  min-height: 100vh;
  color: $fontColor;
  padding: 5%;
  border-radius: 50px;
}
