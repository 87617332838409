@import '../variables';

.header {
  margin: 0 auto 50px auto;
  width: 75%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    text-align: center;
    width: 85%;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width : 768px) and (max-width : 1024px) {
    width: 90%;
  }
}

.photo {
  flex: 1;
  text-align: center;
  width: 30%;
  border-radius: 50%;
  border: 5px solid #FFF;
  box-shadow: 0 0 15px 15px  rgba(0, 0, 0, .1);

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.bio {
  flex: 3;
  margin: 0 0 0 30px;
  align-self: center;

  @media only screen and (max-width: 767px) {
    margin: 30px 0 0 0;
  }
}

.name {
  margin-top: 0;
  font-size: 2em;
}

.about {
  color: $secondaryFontColor;
}
